import React from 'react';
import ReactDOM from 'react-dom';

import { Skills } from './Skills';

function Example() {
    return (
        <div className="container border-1">
            <div className="header px4 py2">
                <h1 className="mb0">
                    Karl Fletcher
                </h1>
                <p className="mt0 italic">
                    8+ Years Software Development Experience
                </p>
            </div>
            <div className="body">
                <div className="history py2 px4">
                    <section>
                        <p className="mt0">I started my career straight out of the University of Nottingham in a graduate web developer role focussed primarily on developing bespoke web-based systems. After a 2 year tenure at my first place of employment, I moved into the world of Digital Marketing where I have honed my development skills and progressed in a number of different frameworks and languages. I have now held my current position for 3 years.</p>
                        <p>I am primarily a PHP developer at this moment in time however I also have a senior level of experience in ReactJS, both in managing the overall architecture of a number of large-scale applications and implementing key state-management structures such as Redux at an advanced level.</p>
                        <p>I have experience with Laravel and I am proficient in SQL and Linux; performing both server maintenance, on a small scale, and database management for a large financial dashboard system.</p>
                        <hr/>
                    </section>
                    <section>
                        <h2>Experience</h2>
                        <div className="date-content-box flex" style={{borderBottom: "1px dashed"}}>
                            <div className="information-date center-height text-center items-center">
                                <p>05-2017</p><p className="px1"> → </p><p>Present</p>
                            </div>
                            <div className="p2">
                                <h3 className="mb0">Motionlab Marketing Ltd.</h3>
                                <p className="mt0">I started at Motionlab in a junior developer role which has over time transitioned into a senior level role. The majority of projects that I have worked on during my time have been Wordpress website builds that integrate with a wide range of different services and platforms. I’ve handled a large range of work including the development of large scale analytics platforms (in ReactJS and Laravel), API integrations handling donation payments for a large UK charity and working on bespoke designer systems for the home improvement industry.</p>
                            </div>
                        </div>
                        <div className="date-content-box flex">
                            <div className="information-date center-height text-center items-center">
                                <p>08-2015</p><p className="px1"> → </p><p>04-2017</p>
                            </div>
                            <div className="p2">
                                <h3 className="mb0">Q2Q-IT</h3>
                                <p className="mt0">Q2Q is where I first started developing in ReactJS and have loved working with the framework ever since. Two key projects I worked on were a Order Management system for a recycling company and a financial dashboard for an online clothing retailer which I still help maintain on a lightweight contract basis to this day. Both were built in ReactJS (ES2015) with a standard PHP backend. Most of my experience in SQL came from this employment as we would work using stored procedures for the majority of database transactions which were all written from scratch.</p>
                            </div>
                        </div>
                        <hr/>
                    </section>
                    <section>
                        <h2>Education</h2>
                        <div className="date-content-box flex" style={{borderBottom: "1px dashed"}}>
                            <div className="information-date center-height text-center items-center">
                                <p>2012</p><p className="px1"> → </p><p>2015</p>
                            </div>
                            <div className="p2">
                                <h3 className="mb0">University of Nottingham</h3>
                                <p className="mt0">BSc Computer Science Degree (2:1)</p>
                            </div>
                        </div>
                        <div className="date-content-box flex">
                            <div className="information-date center-height text-center items-center">
                                <p>2010</p><p className="px1"> → </p><p>2012</p>
                            </div>
                            <div className="p2">
                                <h3 className="mb0">Preston College</h3>
                                <p className="mt0">BTEC Diploma in Software Engineering (D*D*D*)</p>
                            </div>
                        </div>
                        <hr/>
                    </section>
                </div>
                <div className="info py2 px4">
                    <section>
                        <h2 className="mb0 mt0">Personal Information</h2>
                        <hr/>
                        <p className="mb0 bold">Email</p>
                        <p className="text-right italic m0">karl@karlfletcher.co.uk</p>
                        <p className="mb0 bold">Website</p>
                        <p className="text-right italic m0">www.karlfletcher.co.uk</p>
                        <p className="mb0 bold">Phone</p>
                        <p className="text-right italic m0">Email to request</p>
                    </section>
                    
                    <Skills />
                        
                </div>
            </div>
            <div className="footer">

            </div>
        </div>
    );
}

export default Example;

if (document.getElementById('app')) {
    ReactDOM.render(<Example />, document.getElementById('app'));
}
