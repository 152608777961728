import React from 'react';
import ReactDOM from 'react-dom';

export const Skills = () => {

    const starsDescription = {
        5: "Excellent",
        4: "Advanced",
        3: "Intermediate",
        2: "Hobbyist",
        1: "Learning"
    };
    
    const skills = [
        {
            key: 1,
            name: "PHP",
            stars: 5,
        },
        {
            key: 2,
            name: "Laravel",
            stars: 3
        },
        {
            key: 3,
            name: "Javascript",
            stars: 5
        },
        {
            key: 4,
            name: "ReactJS",
            stars: 5
        },
        {
            key: 5,
            name: "CSS",
            stars: 4
        },
        {
            key: 6,
            name: "C#",
            stars: 3
        },
        {
            key: 7,
            name: "Linux (Ubuntu)",
            stars: 4
        },
        {
            key: 8,
            name: "SQL (MySQL)",
            stars: 4
        }
    ];

    let renderSkills = skills.map(function(skill){

        let classString = "p1";
        if(skill.key % 2 == 0) {
            classString += " striped";
        }

        let stars = [];
        for(let i = 0; i < skill.stars; i++) {
            stars.push(
                <span key={"full" + i} style={{color: "#ecc700"}}>★</span>
            );
        }

        if(stars.length != 5) {
            for(let i = 0; i < 5 - skill.stars; i++) {
                stars.unshift(
                    <span key={"empty" + i}>☆</span>
                )
            }
        }

        return (
            <div key={skill.key} className={classString}>
                <p className="uppercase mt0 mb0">{skill.name}</p>
                <div className="text-right">
                    <div className="rating">
                        {stars}
                    </div>
                </div>
                <div className="text-right">
                    <small className="italic">{starsDescription[skill.stars]}</small>
                </div>
            </div>
        )
    });

    return (
        <section className="mt4 pt2">
            <h2 className="mb0">Key Development Skills</h2>
            <hr/>
            {renderSkills}
        </section>
    );
}